import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class CareersPage extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet title="Careers | Asgaard Software" />
                <HeaderGeneric title="Careers" subtitle="Careers @ Asgaard" showHome="true" />
                <div id="main">
                    <section id="content" className="main">
                        <h2>Careers</h2>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default CareersPage