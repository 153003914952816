import React from 'react'
import logo from '../assets/images/logo.svg';

const HeaderGeneric = (props) => (
    <header id="header">
        {props.showHome &&
            <nav className=''>
                <a className="" href='/'>
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <span>Asgaard Software</span>
                </a>
            </nav>}
        <h1>{props.title}</h1>
        {props.subtitle &&
            <p>{props.subtitle}</p>}
    </header>
)

export default HeaderGeneric
